import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {
    AlertApiError,
    CheckboxElement,
    EnumDropdownElement,
    FormOrientation,
    ITenantCreating,
    ITenantDeleting,
    ITenantUpdating,
    MultilineTextElement,
    NumericFieldElement,
    RenderAntdGraphQLList,
    RenderAntdStandaloneForm,
    TextFieldElement,
    TitleAndContentLayout
} from "@ib-tehnologije/react-widgets";
import {useTranslation} from "react-i18next";
import {
    CreateItemMutation,
    DeleteItemMutation,
    ItemCreateDtoInput,
    ItemDeleteDtoInput,
    ItemType,
    ItemUpdateDtoInput,
    UpdateItemMutation,
    useCreateItemMutation,
    useDeleteItemMutation, useGetItemsLazyQuery,
    useGetItemsQuery, useRestoreDeletedItemMutation, useRestoreDeletedWorkOrderMutation,
    useUpdateItemMutation
} from "../graphql-types";
import {ActualItemReturnDto} from "../ReturnTypes/ActualItemReturnDto";
import {useUnitOfMeasureIdPicker} from "../Pickers/useUnitOfMeasureIdPicker";
import {ncFormat, nFormat} from "../index";
import {ButtonProps, notification, Typography} from "antd";
import {useItemPicker} from "../Pickers/useItemPicker";
import {useVATTypePicker} from "../Pickers/useVATTypePicker";
import {useFinancialAccountPicker} from "../Pickers/useFinancialAccountPicker";
import {useLocalStorage} from "usehooks-ts";
import {UndoOutlined} from "@ant-design/icons";

const ManagerItemsPage: FC = () => {
    const {t} = useTranslation();
    const [a, q] = useGetItemsLazyQuery();
    const [updateItem] = useUpdateItemMutation();
    const [createItem] = useCreateItemMutation();
    const [deleteItem] = useDeleteItemMutation();
    const unitOfMeasureIdPicker = useUnitOfMeasureIdPicker();
    const financialAccountPicker = useFinancialAccountPicker();
    const vatTypePicker = useVATTypePicker();
    const itemIdPicker = useItemPicker({
        translationKey: "rentalServiceItemId",
        itemType: ItemType.Service,
        valueKey: "rentalServiceItem"
    });
    const [baseFilterData, setBaseFilterData] = useLocalStorage("itemspagefilter", {
        isDeleted: false
    });
    const [isRestoring, setIsRestoring] = useState(false);
    const [filterLoading, setFilterLoading] = useState<boolean>(false);
    const [restoreItem, restorationData] = useRestoreDeletedItemMutation();
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
        console.log("baseFilterData", baseFilterData);
        reloadFilter(baseFilterData);
    }, [baseFilterData]);

    const reloadFilter = useCallback((values: any) => {
        if (!filterLoading) {
            setFilterLoading(true);
            a({
                variables: {
                    ...q.variables,
                    where: {
                        and: [values.search ? {
                            or: [{
                                name: {
                                    contains: values.search
                                },
                            }, {
                                description: {
                                    contains: values.search
                                },
                            }, {
                                externalId: {
                                    contains: values.search
                                },
                            }]
                        } : {},
                            values.unitOfMeasureId !== undefined ? {
                                unitOfMeasureId: {
                                    eq: values.unitOfMeasureId
                                }
                            } : {},
                            values.itemType !== undefined ? {
                                itemType: {
                                    eq: values.itemType
                                }
                            } : {},
                        ],
                    },
                    isDeleted: values.isDeleted
                }
            }).finally(() => {
                setFilterLoading(false);
            });
        }
    }, [q.variables, filterLoading]);

    const creating = useMemo<ITenantCreating<CreateItemMutation, ActualItemReturnDto, ItemCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({rows: 3})
            },
            unitOfMeasureId: unitOfMeasureIdPicker,
            unitPrice: {
                type: new NumericFieldElement({})
            },
            unitsContained: {
                type: new NumericFieldElement({})
            },
            itemType: {
                type: new EnumDropdownElement({enumData: ItemType})
            },
            rentalServiceItemId: itemIdPicker,
            rentalThresholdDays: {
                type: new NumericFieldElement({})
            },
            financialAccountId: financialAccountPicker,
            vatTypeId: vatTypePicker,
            externalId: {
                type: new TextFieldElement({})
            }
        },
        selectionToDuplicateDto: (selection) => ({
            name: selection.name,
            description: selection.description,
            unitOfMeasureId: selection.unitOfMeasureId,
            unitOfMeasure: selection.unitOfMeasure,
            unitPrice: selection.unitPrice,
            unitsContained: selection.unitsContained,
            itemType: selection.itemType,
            rentalServiceItemId: selection.rentalServiceItemId,
            rentalThresholdDays: selection.rentalThresholdDays,
            rentalServiceItem: selection.rentalServiceItem ? {
                id: selection.rentalServiceItem.id,
                name: selection.rentalServiceItem.name,
                description: selection.rentalServiceItem.description,
                unitsContained: selection.rentalServiceItem.unitsContained,
                unitPrice: selection.rentalServiceItem.unitPrice,
                unitOfMeasureId: selection.rentalServiceItem.unitOfMeasureId,
            } : undefined,
            vatTypeId: selection.vatTypeId,
            vatType: selection.vatType ? {
                id: selection.vatType.id,
                name: selection.vatType.name,
                description: selection.vatType.description,
                rate: selection.vatType.rate,
            } : undefined,
            financialAccountId: selection.financialAccountId,
            financialAccount: selection.financialAccount ? {
                id: selection.financialAccount.id,
                name: selection.financialAccount.name,
                description: selection.financialAccount.description,
                number: selection.financialAccount.number,
            } : undefined,
            externalId: selection.externalId
        }),
        formDataToDto: (formData) => ({
            name: formData.name,
            description: formData.description,
            unitOfMeasureId: formData.unitOfMeasureId,
            unitPrice: +(formData.unitPrice ?? 0),
            unitsContained: +(formData.unitsContained ?? 0),
            itemType: formData.itemType,
            rentalThresholdDays: +(formData.rentalThresholdDays ?? 0),
            rentalServiceItemId: formData.rentalServiceItemId,
            vatTypeId: formData.vatTypeId,
            financialAccountId: formData.financialAccountId,
            externalId: formData.externalId
        }),
        baseFormData: {},
        fn: createItem
    }), []);

    const updating = useMemo<ITenantUpdating<UpdateItemMutation, ActualItemReturnDto, ItemUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        formDefinition: {
            name: {
                type: new TextFieldElement({})
            },
            description: {
                type: new MultilineTextElement({rows: 3})
            },
            unitOfMeasureId: unitOfMeasureIdPicker,
            unitPrice: {
                type: new NumericFieldElement({})
            },
            unitsContained: {
                type: new NumericFieldElement({})
            },
            itemType: {
                type: new EnumDropdownElement({enumData: ItemType})
            },
            rentalServiceItemId: itemIdPicker,
            rentalThresholdDays: {
                type: new NumericFieldElement({})
            },
            financialAccountId: financialAccountPicker,
            vatTypeId: vatTypePicker,
            externalId: {
                type: new TextFieldElement({})
            }
        },
        selectionToBaseFormData: (selection) => ({
            id: selection?.id,
            name: selection?.name,
            description: selection?.description,
            unitOfMeasure: selection?.unitOfMeasure,
            unitOfMeasureId: selection?.unitOfMeasureId,
            unitPrice: selection?.unitPrice,
            unitsContained: selection?.unitsContained,
            itemType: selection?.itemType,
            rentalServiceItemId: selection?.rentalServiceItemId,
            rentalThresholdDays: selection?.rentalThresholdDays,
            rentalServiceItem: selection?.rentalServiceItem ? {
                id: selection?.rentalServiceItem?.id,
                name: selection?.rentalServiceItem?.name,
                description: selection?.rentalServiceItem?.description,
                unitsContained: selection?.rentalServiceItem?.unitsContained,
                unitPrice: selection?.rentalServiceItem?.unitPrice,
                unitOfMeasureId: selection?.rentalServiceItem?.unitOfMeasureId,
            } : undefined,
            vatTypeId: selection?.vatTypeId,
            vatType: selection?.vatType ? {
                id: selection?.vatType.id,
                name: selection?.vatType.name,
                description: selection?.vatType.description,
                rate: selection?.vatType.rate,
            } : undefined,
            financialAccountId: selection?.financialAccountId,
            financialAccount: selection?.financialAccount ? {
                id: selection?.financialAccount.id,
                name: selection?.financialAccount.name,
                description: selection?.financialAccount.description,
                number: selection?.financialAccount.number,
            } : undefined,
            externalId: selection?.externalId
        }),
        formDataToDto: (formData) => ({
            id: formData.id,
            name: formData.name,
            description: formData.description,
            unitOfMeasureId: formData.unitOfMeasureId,
            unitPrice: +(formData.unitPrice ?? 0),
            unitsContained: +(formData.unitsContained ?? 0),
            itemType: formData.itemType,
            rentalThresholdDays: +(formData.rentalThresholdDays ?? 0),
            rentalServiceItemId: formData.rentalServiceItemId,
            vatTypeId: formData.vatTypeId,
            financialAccountId: formData.financialAccountId,
            externalId: formData.externalId
        }),
        fn: updateItem
    }), []);

    const deleting = useMemo<ITenantDeleting<DeleteItemMutation, ActualItemReturnDto, ItemDeleteDtoInput, "data">>(() => ({
        modelKey: "data",
        selectionToDto: (selection) => ({
            id: selection?.id
        }),
        fn: deleteItem
    }), []);
    
    const selectionButtons = useCallback((selectedItems: ActualItemReturnDto[] | undefined) => {
        const buttons: ButtonProps[] = [];

        if (selectedItems?.length === 1 && selectedItems[0].isDeleted) {
            buttons.push({
                onClick: async () => {
                    setIsRestoring(true);
                    try {
                        await restoreItem({
                            variables: {
                                dto: {
                                    itemId: selectedItems[0].id
                                }
                            }
                        });

                        api.success({message: t("item-order-restored")});

                        q.refetch();
                    } catch (e: any) {
                        AlertApiError(e);
                    } finally {
                        setIsRestoring(false);
                    }
                },
                loading: isRestoring,
                children: t("restore"),
                icon: <UndoOutlined/>
            });
        }

        return buttons;
    },[restoreItem, api, t, q, isRestoring]);

    return <TitleAndContentLayout title={"items"} pad>
        {contextHolder}
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%"
            }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: 250,
            }}>
                <RenderAntdStandaloneForm
                    formSettings={{inline: true, orientation: FormOrientation.VERTICAL}}
                    loading={q.loading}
                    baseFormData={baseFilterData}
                    formDefinition={{
                        search: {
                            type: new TextFieldElement({})
                        },
                        unitOfMeasureId: unitOfMeasureIdPicker,
                        itemType: {
                            type: new EnumDropdownElement({enumData: ItemType})
                        },
                        isDeleted: {
                            type: new CheckboxElement({
                                tristate: true
                            })
                        }
                    }}
                    submission={{
                        title: "start-search",
                        onSubmit: (values) => {
                            setBaseFilterData(values as any);
                        }
                    }}
                />
            </div>
            <div style={{flex: 1, width: "100%"}}>
                <RenderAntdGraphQLList
                    deleting={deleting}
                    tableData={q.data?.items}
                    inlineSelectionButtons={selectionButtons}
                    columns={[{
                        columnId: "id",
                        renderCell: (row) => <div style={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <Typography.Text>{row.id}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.externalId}</Typography.Text>
                        </div>
                    }, {
                        columnId: "nameAndDescription",
                        renderCell: (row) => <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography.Text>{row.name}</Typography.Text>
                            <Typography.Text type={"secondary"}>{row.description}</Typography.Text>
                        </div>,
                    }, {
                        columnId: "unitsContained",
                        renderCell: (row) => <span>{nFormat.format(row.unitsContained)}</span>,
                    }, {
                        columnId: "unitOfMeasure",
                        renderCell: (row) => <span>{row.unitOfMeasure?.name}</span>,
                    }, {
                        columnId: "unitPrice",
                        renderCell: (row) => <span>{ncFormat.format(row.unitPrice)}</span>,
                    }, {
                        columnId: "itemType",
                        renderCell: (row) => <span>{t(`enums:${row.itemType}`)}</span>,
                    }, {
                        columnId: "rentalThresholdDays",
                        renderCell: (row) => row.rentalThresholdDays ? <span>{row.rentalThresholdDays}</span> : null,
                    }]}
                    query={q}
                    loading={q.loading}
                    creating={creating}
                    getRowId={g => g.id}
                    updating={updating}
                />
            </div>
        </div>
    </TitleAndContentLayout>
}

export default ManagerItemsPage;