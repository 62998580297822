import {useMemo} from "react";
import {FormEvent, ITenantCreating, MultilineTextElement, NumericFieldElement} from "@ib-tehnologije/react-widgets";
import {
    CreateWorkOrderItemMutation,
    CreateWorkOrderMutation, GetWorkOrderTotalsDocument, useCreateWorkOrderItemMutation,
    WorkOrderCreateDtoInput,
    WorkOrderItemCreateDtoInput
} from "../graphql-types";
import {ActualWorkOrderReturnDto} from "../ReturnTypes/ActualWorkOrderReturnDto";
import {ActualWorkOrderItemReturnDto} from "../ReturnTypes/ActualWorkOrderItemReturnDto";
import {useItemPicker} from "../Pickers/useItemPicker";
import {useFilePicker} from "../Pickers/useFilePicker";

export const useWorkOrderItemCreating = (forWorkOrderId: number): ITenantCreating<CreateWorkOrderItemMutation, ActualWorkOrderItemReturnDto, WorkOrderItemCreateDtoInput, "data"> => {
    const [create] = useCreateWorkOrderItemMutation({
        refetchQueries: [{
            query: GetWorkOrderTotalsDocument,
            variables: {workOrderId: forWorkOrderId}
        }]
    });
    const itemIdPicker = useItemPicker();
    const filePicker = useFilePicker();

    return useMemo<ITenantCreating<CreateWorkOrderItemMutation, ActualWorkOrderItemReturnDto, WorkOrderItemCreateDtoInput, "data">>(() => ({
        modelKey: "data",
        baseFormData: {
            workOrderId: forWorkOrderId
        },
        formDataToDto: formData => ({
            itemId: formData.itemId,
            files: (formData.files ?? []).map((file: any) => ({id: file.response.id})),
            note: formData.note,
            quantity: formData.quantity,
            workOrderId: forWorkOrderId,
            itemUnitPrice: formData.itemUnitPrice
        }),
        formDefinition: {
            itemId: itemIdPicker,
            note: {
                type: new MultilineTextElement({rows: 3}),
            },
            quantity: {
                type: new NumericFieldElement({}),
            },
            itemUnitPrice: {
                type: new NumericFieldElement({}),
                dependsOn: [{
                    key: "itemId",
                    onChanged: async (data, value, initialized) => {
                        if(data?.item?.unitPrice){
                            return [{
                                eventType: FormEvent.UpdateValueAndStorage,
                                payload: {
                                    formKey: "itemUnitPrice",
                                    value: data?.item?.unitPrice,
                                    storage: data?.item?.unitPrice
                                }
                            }];
                        }
                    }
                }]
            },
            files: filePicker
        },
        selectionToDuplicateDto: selection => ({
            itemId: selection.itemId,
            files: selection.files,
            note: selection.note,
            quantity: selection.quantity,
            workOrderId: forWorkOrderId,
            itemUnitPrice: selection.itemUnitPrice
        }),
        fn: create
    }), [create, forWorkOrderId]);
}