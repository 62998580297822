import {useMemo} from "react";
import {
    CheckboxElement, GroupElement, ITenantCreating,
    MultilineTextElement, NumericFieldElement, TextFieldElement, TitleElement, VerticalGroupElement
} from "@ib-tehnologije/react-widgets";
import {
    CreateWorkOrderTemplateMutation, useCreateWorkOrderTemplateMutation,
    WorkOrderPriority,
    WorkOrderTemplateCreateDtoInput
} from "../graphql-types";
import {ActualWorkOrderTemplateReturnDto} from "../ReturnTypes/ActualWorkOrderTemplateReturnDto";
import {useTranslation} from "react-i18next";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import {useVehiclePicker} from "../Pickers/useVehiclePicker";
import {useOrganizationalEntityPicker} from "../Pickers/useOrganizationalEntityPicker";
import {useItemPicker} from "../Pickers/useItemPicker";
import {useExecutionDayPicker} from "../Pickers/useExecutionDayPicker";
import {useWorkOrderPriorityPicker} from "../Pickers/useWorkOrderPriorityPicker";

export const useWorkOrderTemplateCreating = (): ITenantCreating<CreateWorkOrderTemplateMutation, ActualWorkOrderTemplateReturnDto, WorkOrderTemplateCreateDtoInput, "data"> => {
    const [createWorkOrderTemplate] = useCreateWorkOrderTemplateMutation();
    const {t} = useTranslation();
    const companyIdPicker = useCompanyPicker();
    const companyLocationIdPicker = useCompanyLocationPicker();
    const vehicleIdPicker = useVehiclePicker();
    const organizationalEntityIdPicker = useOrganizationalEntityPicker();
    const itemIdPicker = useItemPicker();
    const executionDayPicker = useExecutionDayPicker();
    const workOrderPriorityPicker = useWorkOrderPriorityPicker();

    return useMemo<ITenantCreating<CreateWorkOrderTemplateMutation, ActualWorkOrderTemplateReturnDto, WorkOrderTemplateCreateDtoInput, "data">>(() => ({
            modelKey: "data",
            formDefinition: {
                headAndItems: {
                    type: new GroupElement({
                        head: {
                            type: new VerticalGroupElement({
                                basics: {
                                    type: new TitleElement({})
                                },
                                nameDeactivatedAndPriority: {
                                    type: new VerticalGroupElement({
                                        name: {
                                            type: new TextFieldElement({})
                                        },
                                        deactivated: {
                                            type: new CheckboxElement({})
                                        },
                                        priority: workOrderPriorityPicker,
                                    })
                                },
                                description: {
                                    type: new MultilineTextElement({rows: 3})
                                },
                                executionFrequency: {
                                    type: new TitleElement({})
                                },
                                executionDay: executionDayPicker,
                                executionDayOfMonth: {
                                    type: new NumericFieldElement({})
                                },
                                companyAndLocation: {
                                    type: new TitleElement({})
                                },
                                companyId: companyIdPicker,
                                companyLocationId: companyLocationIdPicker,
                                freeformLocation: {
                                    type: new TextFieldElement({})
                                },
                                executionResources: {
                                    type: new TitleElement({})
                                },
                                vehicleId: vehicleIdPicker,
                                organizationalEntityId: organizationalEntityIdPicker,
                            })
                        },
                    })
                }
            },
            selectionToDuplicateDto: (selection) => ({
                name: selection.name,
                description: selection.description,
                companyLocationId: selection.companyLocationId,
                deactivated: selection.deactivated,
                executionDay: selection.executionDay,
                executionDayOfMonth: selection.executionDayOfMonth,
                organizationalEntityId: selection.organizationalEntityId,
                vehicleId: selection.vehicleId,
                freeformLocation: selection.freeformLocation,
                companyId: selection.companyId,
                priority: selection.priority,
            }),
            formDataToDto:
                (formData) => ({
                    name: formData.name,
                    description: formData.description,
                    deactivated: formData.deactivated,
                    executionDay: formData.executionDay,
                    executionDayOfMonth: formData.executionDayOfMonth ? +formData.executionDayOfMonth : undefined,
                    organizationalEntityId: formData.organizationalEntityId,
                    companyId: formData.companyId,
                    companyLocationId: formData.companyLocationId,
                    vehicleId: formData.vehicleId,
                    freeformLocation: formData.freeformLocation,
                    priority: formData.priority,
                }),
            baseFormData: {
                priority: WorkOrderPriority.Medium,
            },
            fn: createWorkOrderTemplate
        }),
        []
    )
        ;
};