import {useMemo} from "react";
import {CustomSearchBoxElement} from "@ib-tehnologije/react-widgets";
import {ItemType, useGetItemsLazyQuery, useGetItemsQuery} from "../graphql-types";

export const useItemPicker = (props?: {
    translationKey?: string,
    itemType?: ItemType | undefined,
    valueKey?: string
} | undefined) => {
    return useMemo(() => ({
        type: new CustomSearchBoxElement({
            query: useGetItemsLazyQuery,
            getQueryData: ({data}) => data?.items,
            getKey: (f) => f.id,
            makeTitle: (f) => f.name,
            modifyQueryOptions: (options) => ({
                ...options,
                variables: {
                    ...options.variables,
                    take: 50
                }
            }),
            makeWhere: (values, search) => ({
                and: [{
                    or: [{
                        name: {
                            contains: search
                        }
                    }, {
                        description: {
                            contains: search
                        }
                    }]
                }, props?.itemType !== undefined ? {
                    itemType: {
                        eq: props?.itemType
                    }
                } : {}],
            }),
            makeSubtitle: (f) => `${f.externalId || ""}${f.description?.trim() ? ` (${f.description})` : ""}`,
            valueKey: props?.valueKey ?? "item",
        }),
        translationKey: props?.translationKey ?? "item",
    }), []);
}