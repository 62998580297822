import {useMemo} from "react";
import {
    CustomRenderElement,
    DateElement, FormTextElement,
    GroupElement, ITenantUpdating, MultilineTextElement, TextFieldElement, TitleElement, VerticalGroupElement
} from "@ib-tehnologije/react-widgets";
import {
    UpdateWorkOrderMutation,
    useUpdateWorkOrderMutation,
    WorkOrderUpdateDtoInput,
    
    
    
} from "../graphql-types";
import {ActualWorkOrderReturnSingleDto} from "../ReturnTypes/ActualWorkOrderReturnDto";
import {useCompanyPicker} from "../Pickers/useCompanyPicker";
import {useCompanyLocationPicker} from "../Pickers/useCompanyLocationPicker";
import {useVehiclePicker} from "../Pickers/useVehiclePicker";
import {useOrganizationalEntityPicker} from "../Pickers/useOrganizationalEntityPicker";
import {useItemPicker} from "../Pickers/useItemPicker";
import {reorderEnum} from "../Helpers/reorderEnum";
import {useTranslation} from "react-i18next";
import {FileOutlined} from "@ant-design/icons";
import {AuthContext} from "../Contexts/AuthContext";
import {usePickupRequestIdPicker} from "../Pickers/usePickupRequestIdPicker";
import {useFilePicker} from "../Pickers/useFilePicker";
import {Modal} from "antd";
import {useWorkOrderPriorityPicker} from "../Pickers/useWorkOrderPriorityPicker";

export const useWorkOrderUpdating = (): ITenantUpdating<UpdateWorkOrderMutation, ActualWorkOrderReturnSingleDto, WorkOrderUpdateDtoInput, "data"> => {
    const {t} = useTranslation();
    const [update] = useUpdateWorkOrderMutation();
    const companyIdPicker = useCompanyPicker();
    const companyLocationIdPicker = useCompanyLocationPicker();
    const vehicleIdPicker = useVehiclePicker();
    const organizationalEntityIdPicker = useOrganizationalEntityPicker();
    const itemIdPicker = useItemPicker();
    const pickupRequestIdPicker = usePickupRequestIdPicker();
    const filePicker = useFilePicker();
    const [modal, contextHolder] = Modal.useModal();
    const workOrderPriorityPicker = useWorkOrderPriorityPicker();

    return useMemo<ITenantUpdating<UpdateWorkOrderMutation, ActualWorkOrderReturnSingleDto, WorkOrderUpdateDtoInput, "data">>(() => ({
        modelKey: "data",
        baseFormData: {},
        selectionToBaseFormData: (selection) => ({
            company: selection?.company,
            companyId: selection?.companyId,
            companyLocation: selection?.companyLocation,
            companyLocationId: selection?.companyLocationId,
            freeformCompany: selection?.freeformCompany,
            freeformLocation: selection?.freeformLocation,
            id: selection?.id,
            pickupRequest: selection?.pickupRequest,
            managerNote: selection?.managerNote,
            organizationalEntity: selection?.organizationalEntity,
            organizationalEntityId: selection?.organizationalEntityId,
            pickupRequestId: selection?.pickupRequestId,
            priority: selection?.priority,
            scheduledDate: selection?.scheduledDate,
            vehicle: selection?.vehicle,
            vehicleId: selection?.vehicleId,
            noteForAccounting: selection?.noteForAccounting,
            files: (selection?.files ?? []).map((file: any) => ({
                fileName: file.fileName,
                name: file.fileName,
                status: "done",
                response: file,
                uid: file.id,
            })),
            fieldWorkerNote: selection?.fieldWorkerNote,
        }),
        formDataToDto: (formData) => ({
            companyLocationId: formData.companyLocationId,
            description: formData.description,
            managerNote: formData.managerNote,
            organizationalEntityId: formData.organizationalEntityId,
            vehicleId: formData.vehicleId,
            pickupRequestId: formData.pickupRequestId,
            priority: formData.priority,
            companyId: formData.companyId,
            freeformLocation: formData.freeformLocation,
            freeformCompany: formData.freeformCompany,
            noteForAccounting: formData.noteForAccounting,
            scheduledDate: formData.scheduledDate,
            id: formData.id,
            files: (formData.files ?? []).map((file: any) => ({id: file.response.id})),
        }),
        formDefinition: {
            contextHolder: {
                type: new CustomRenderElement({renderer: () => contextHolder})
            },
            headAndItems: {
                type: new GroupElement({
                    head: {
                        style: {
                            maxWidth: 600
                        },
                        type: new VerticalGroupElement({
                            basics: {
                                type: new TitleElement({})
                            },
                            pickupRequestId: pickupRequestIdPicker,
                            pickupLocationNote: {
                                type: new FormTextElement({
                                    titleFormatter: (values: any) => {
                                        return values?.pickupRequest?.locationNote;
                                    }
                                })
                            },
                            pickupServiceNote: {
                                type: new FormTextElement({
                                    titleFormatter: (values: any) => {
                                        return values?.pickupRequest?.serviceNote;
                                    }
                                })
                            },
                            scheduledDate: {
                                type: new DateElement({})
                            },
                            priority: workOrderPriorityPicker,
                            companyAndLocation: {
                                type: new TitleElement({})
                            },
                            companyAndLocationEntry: {
                                type: new VerticalGroupElement({
                                    companyId: companyIdPicker,
                                    freeformCompany: {
                                        type: new TextFieldElement({})
                                    },
                                    companyLocationId: companyLocationIdPicker,
                                    freeformLocation: {
                                        type: new TextFieldElement({})
                                    },
                                })
                            },
                            executionResources: {
                                type: new TitleElement({})
                            },
                            vehicleAndOrOrganizationalEntity: {
                                type: new VerticalGroupElement({
                                    vehicleId: vehicleIdPicker,
                                    organizationalEntityId: organizationalEntityIdPicker,
                                })
                            },
                            notes: {
                                type: new TitleElement({})
                            },
                            managerNotes: {
                                type: new VerticalGroupElement({
                                    managerNote: {
                                        type: new MultilineTextElement({
                                            rows: 5
                                        })
                                    },
                                    noteForAccounting: {
                                        type: new MultilineTextElement({
                                            rows: 5
                                        })
                                    },
                                    fieldWorkerNote: {
                                        type: new FormTextElement({
                                            titleFormatter: (values: any) => values?.fieldWorkerNote
                                        })
                                    }
                                })
                            },
                            filesSection: {
                                type: new VerticalGroupElement({
                                    filesTitle: {
                                        type: new TitleElement({})
                                    },
                                    files: filePicker
                                })
                            }
                        })
                    },
                })
            },
        },
        fn: update,
    }), [update, filePicker]);
}